function syncTableWidths(srcTable, targetTable) {
    var cells = srcTable.querySelector('thead').querySelectorAll('td,th');
    var stickyCells = targetTable.querySelectorAll('td,th');

    for (var i = 0; i < cells.length; i++) {
        var cell = cells[i];
        var stickyCell = stickyCells[i];

        if (stickyCell) {
            stickyCell.style.whiteSpace = 'normal'; 


            stickyCell.style.width = cell.getBoundingClientRect().width + 'px';


            stickyCell.style.minWidth = stickyCell.style.width;
            stickyCell.style.maxWidth = stickyCell.style.width;
        }
    }
}

function syncStickyHeader(srcTable, targetTable, stickyContainer, scrollEl) {
    if (!stickyContainer || !targetTable || !scrollEl) {
        return;
      } 


      var delta = scrollEl.offsetWidth - scrollEl.clientWidth;
      var bar = stickyContainer.querySelector('[bar]');

      if (delta) {
        if (!bar) {
          bar = document.createElement('td');
          bar.setAttribute('bar', 'true');
          var tr = stickyContainer.firstElementChild.firstElementChild.firstElementChild
          tr.append(bar);//modified
        }

        bar.style.width = delta + 'px';
      } else if (bar) {
        var tr = stickyContainer.firstElementChild.firstElementChild.firstElementChild
        tr.removeChild(bar);//modified
      }

      var headerHeight = srcTable.querySelector('thead').offsetHeight;
      srcTable.style.marginTop = -headerHeight - 1 + 'px'
}

function syncStickyHeaderScroll(stickyDiv, scrollDiv) {
    scrollDiv.onscroll = function () {
        stickyDiv.scrollLeft = scrollDiv.scrollLeft;
      }
}
